@import '../../variables.scss';

.edit-icon {
    &:hover {
        cursor: pointer;
        color: $color-green;
    }
    &.highlight {
        color: #33a6f8;
        &:hover {
            cursor: pointer;
            color: $color-green;
        }
    }
}
@import 'src/variables';

.form-select-country {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 500px;
  &_title {
    font-size: 18px;
    font-family: "MontserratRegular", "sans-serif";
    color: $textColor;
  }
  &_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    margin: 0;
    padding: 0;
    & li {
      text-decoration: none;
      list-style: none;
      font-size: 16px;
      color: $textColor;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
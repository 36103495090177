.normatdoc-block {
    padding: 15px 20px;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.2s ease;
    backface-visibility: hidden;
    display: flex;
    flex-direction: row;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    z-index: 10;
    &:hover {
        cursor: pointer;
        transform: translateX(2px) translateY(-2px) translateZ(0);
        transition: 0.2s ease;
    }
    &_text {
        margin-left: 25px;
        display: inline;
        padding: 0px;
        width: 100%;
        // margin: 0px;
        font-family: "OpenSans Regular", sans-serif;
        font-size: 16px;
        z-index: 1;
    }
}

@import '../../variables.scss';

.my-rs-input {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    border: transparent;
    outline: none;
    padding: 0 5px;
    color: $textColor;
    &.readonly {
        background-color: rgba($color-green, 0.1);
    }
    &:focus {
        border: 1px solid $color-green;
    }
    &.type-button {
        &:hover {
            background-color: $color-green;
        }
    }
}
@import '../../variables.scss';

.rc-tree-child-tree {
    display: block;
  }
  
  .node-motion {
    transition: all .3s;
    overflow-y: hidden;
  } 

.menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 193px;
    min-height: 100vh;
    border-right: 1px solid $greyColor;
    align-items: center;
    justify-items: center;
    &-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-logo {
            width: 31px;
            height: 45px;
        }
        &-text {
            text-align: center;
            font-family: 'MontserratMedium', 'sans-serif';
            font-size: 16px;
            color: $textColor;
            line-height: 1.15em;
        }
    }
    &-nav {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        height: 100%;
        &_header {
            
            font-family: 'OpenSans SemiBold', sans-serif;
            font-size: 18px;
            color: $color-grey;
        }
    }
}

.logout-button {
    text-decoration: none;
    color: black;
    font-family: 'OpenSans Regular', sans-serif;
    font-size: 14px;
    &:hover {
        text-decoration: none;
        color: black;
        opacity: 0.8;
    }
}
@import '../../variables.scss';

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 5px;
    font-family: 'MontserratMedium', sans-serif;
    font-size: 16px;
    height: 30px;
    padding: 10px 20px;
    transition: background-color .2s ease, border .2s ease;
    color: #ffffff;
    background-color: $color-green;
    &:hover {
        cursor: pointer;
        background-color: transparent;
        border: 1px solid $color-green;
        color: $textColor;
    }
    &.disable {
        background-color: grey;
    }
}
.document-tab-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.download-file-button {
  padding: 10px 10px;
  color: #ffffff;
  background-color: #5CDB94;
  font-family: "MontserratMedium", sans-serif;
  display: inline-block;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: background-color 0.2s ease, border 0.2s ease;
  &:hover {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #5CDB94;
    color: #05396B;
  }
  &_wrapper {
    & input {
      display: none;
    }
  }
}

.drag-active-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.6);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    font-family: "MontserratBold", sans-serif;
    font-size: 20px;
  }
}
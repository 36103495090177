@import '../../variables.scss';

.wrap-expand-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.expand-icon {
  &:before {
    display: block;
    content: '';
    width: 20px;
    border: 1px solid $textColor;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  &:after {
    display: block;
    content: '';
    width: 20px;
    border: 1px solid $textColor;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)  rotate(90deg);
    transition: transform 0.3s ease;
  }
  &.expand {
    &:after {
      transform: rotate(180deg);
    }
  }
}
.document-wrapper {
  position: relative;
  width: 120px;
  height: 160px;
  overflow: hidden;
  & img {
    padding: 20px;
    width: 120px;
    height: 120px;
    transition: transform 0.2s ease;
  }
  & p {
    text-align: center;
    &:hover {
      cursor: pointer;
      color: blue;
    }
  }
}

.document-delete-button {
  position: absolute !important;
  top: 0;
  right: 0;
}

.document-img-wrapper {

}
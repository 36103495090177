@import '../../variables.scss';

.rs-picker-tag .rs-tag {
    font-family: 'MontserratMedium', 'sans-serif';
    font-size: 12px;
    color: $textColor;
}

.rs-check-item .rs-checkbox-checker>label {
    font-family: 'MontserratRegular', 'sans-serif';
    font-size: 14px;
    color: $textColor
}

.rs-table {
    border-radius: 10px;
}

.rs-table-cell-content {
    white-space: pre-line;
    color: $textColor;
    font-size: 14px;
    font-family: 'MontserratMedium', sans-serif;
    border-left: 1px solid rgba($color-grey-border, 0.2);
    border-bottom: 1px solid $color-grey-border;
    position: relative;
}

.rs-table-column-resize-spanner {
    border-left: transparent;
}

.rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover~.rs-table-column-resize-spanner,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
    background-color: transparent;
}

.rs-table-column-resize-spanner:after, .rs-table-column-resize-spanner:before {
    border: transparent;
}

.rs-table-cell-header-icon-sort {
    color: #FFFFFF;
    font-size: 20px;
}

.table-container {
    width: 95%;
}

.rs-table-cell-header-sort-wrapper {
    margin-left: 0;
}

.rs-input:hover:not(:disabled) {
    border-color: $color-green;
}
.rs-input:focus:not(:disabled) {
    border-color: $color-green;
}

.rs-input:focus {
    box-shadow: none;
}

.rs-picker.rs-picker-focused, .rs-picker:not(.rs-picker-disabled):hover {
    border-color: $color-green;
    cursor: pointer;
}

.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
    box-shadow: 0 0 0 3px  $color-green;
}

.rs-table-row {
    border: transparent;
}

.rs-table {
    border: transparent;
}

.rs-table-hover .rs-table-row:hover, .rs-table-row-header, .rs-table-row-header .rs-table-cell {
    background: transparent;
}




.pagination-button_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    button.save {
        border-radius: 5px;
        padding: 5px 5px;
        height: 30px;
        margin-left: 15px;
        border: 1px solid transparent;
        background-color: $color-green;
        font-family: 'MontserratMedium', sans-serif;
        transition: background-color 0.2s ease, border-color 0.2s ease;
        color: white;
        &:hover {
            cursor: pointer;
            background-color: transparent;
            border: 1px solid $color-green;
            color: $textColor;
        }
    }
}

@import '../../../../variables';

.input-with-label-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
}

.input-with-label-block {
    &__text {
        font-family: 'MontserratBold', 'sans-serif';
        color: $textColor;
        line-height: 40px;
    }
    &_input {
        height: 30px;
        outline: none;
        border: 1px solid $color-grey;
        font-size: 16px;
        color: $textColor;
        font-family: 'MontserratRegular', sans-serif;
        border-radius: 4px;
        padding: 0 5px;
        &:focus {
            border: 1px solid $color-green;
        }
        &.error {
            border: 1px solid red;
        }
    }
}

.myAutocomplete {
    &-input {
        &.open {
            border-radius: 5px 5px 0px 0px;
            border-bottom: 1px solid $color-green;
        }    
        &.error {
            border: 0.5px solid rgba(255, 0, 0, 0.4);
            box-shadow: 0px 4px 10px rgba(255, 0, 0, 0.25);
        }
    }
    &-option {
        color: black;
        font-family: "OpenSans Regular", sans-serif;
        font-size: 16px;
        padding: 8px;
        border-bottom: 1px solid $color-green;
        &:hover {
            background-color: rgba($color-green, 38%);
            cursor: pointer;
        }
        &:last-child {
            border-bottom: 1px solid transparent;
        }
    }
    &-paper {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        background-color: white;
        border-radius: 0px 0px 5px 5px;
    }
}

.tegs-block {
    margin-bottom: 10px;
    width: 355px;
    &_error {
        font-family: "OpenSans Regular", sans-serif;
        font-size: 12px;
        color: red;
    }
}
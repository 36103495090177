@import '../../variables.scss';

.green-tag {
    height: 20px;
    margin-left: 10px;
    margin-top: 10px;
    display: inline-flex;
    background-color: $color-green;
    border-radius: 50px;
    padding: 5px 10px;
    align-items: center;
    &_text {
        white-space: nowrap;
        color: white;
        font-family: 'OpenSans SemiBold', sans-serif;
        font-size: 15px;
        margin: 0px;
    }
    &_close {
        margin-left: 5px;
        color: white;
        display: flex;
        align-items: center;
        &:hover {
            color: grey;
            cursor: pointer;
        }
    }
}

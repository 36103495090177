@import '../../variables.scss';

.tabs-container {
    display: grid;
    grid-template-columns: 1fr;
}

.tab-text {
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    font-family: 'MontserratMedium', 'sans-serif';
    color: $textColor;
    font-size: 16px;
}

.close {
    margin-left: 5px;
    &:hover {
        color: $color-green;
    }
}

.react-tabs {
    -webkit-tap-highlight-color: transparent;
    box-sizing: content-box;
    height: 100%;
    max-width: calc(100vw - #{$width-menu-plus-width-scroll});
    &__tab-list {
        display: flex;
        flex-direction: row;
        height: $height-tab-panel + px;
        min-height: $height-tab-panel + px;
        border-bottom: 1px solid $color-green;
        margin: 0;
        padding: 0;
    }

    &__tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $color-green;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 5px 5px 0 0;
        min-width: 150px;
        font-family: 'OpenSans SemiBold', sans-serif;
        font-size: 16px;
        box-sizing: content-box;
        transition: min-width .2s ease;
        &--selected {
            border-color: $color-green;
            color: black;
            border-radius: 5px 5px 0 0;
            background-color: $color-tab-selected;
        }

        &:focus {
            outline: none;
        }
    }

    &__tab-panel {
        height: 100%;
        display: none;
        $all-height-tab-panel: $height-tab-panel + $padding-tab-content-top + 1 + px; //because tab panel have border bottom 1 px
        padding: $padding-tab-content-top + px 30px 0px 30px;
        $width-difference-padding: $padding-tab-content-top + $padding-tab-content-top + px;
        box-sizing: content-box;
        &--selected {
            display: block;
            border-left: 1px solid $color-green;
            background-color: $color-tab-selected;
        }
    }
}

.filter-input {
  width: 95%;
  height: 30px;
  outline: none;
  font-size: 16px;
  color: white;
  font-family: 'OpenSans Regular', sans-serif;
  border-color: transparent;
  border-bottom: 1px solid white;
  background: transparent;
  caret-color: white;
}

.field-search-wrap {
  position: absolute;
  left: 3px;
  right: 3px;
  width: 100%;
  bottom: 5px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  & img {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }
}
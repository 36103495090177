@import 'src/variables';

.reqq-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.reqq-search {
    position: relative;
    height: 35px;
    width: 400px;
    background: transparent;
    font-family: 'MontserratMedium', 'sans-serif';
    font-size: 15px;
    color: $textColor;
    padding: 0 45px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid $color-grey;
    outline: none;
    &:focus {
        border: 1px solid $color-green;
    }
    &_icon {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-green;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 7.5px;
        width: 20px;
        height: 20px;
        background-color: $color-grey-light;
        border-radius: 50%;
        color: $textColor;
        transition: background-color .2s ease, color .2s ease;
        &:hover {
            cursor: pointer;
            background-color: $color-green;
            color: white;
        }
    }
}
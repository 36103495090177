@import '../../../variables';

.wrap-form {
  position: fixed;
  display: block;
  bottom: 0;
  left: 50%;
  transition: bottom 0.4s ease, transform 0.4s ease;
  transform: translateX(-50%) translateY(100%);
  &.open {
    bottom: 50px;
    transform: translateX(-50%);
    & .toggle-form {
      &:before {
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%) rotate(20deg);
      }
      &:after {
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translateY(-50%) rotate(-20deg);
      }
    }
  }
}

.form-bottom-table {
  max-height: 500px;
  overflow-y: auto;
  max-width: 700px;
  padding: 40px 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
  &_wrap {
    padding: 25px 10px 0 10px;
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin-bottom: 25px;
  }
  &_field {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &_label {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    font-family: 'MontserratBold', sans-serif;
    color: $textColor;
    margin-bottom: 5px;
  }
  &_checker {
    width: 20px;
    height: 28px;
  }
  &_input {
    display: inline-block;
    height: 30px;
    outline: none;
    border: 1px solid $color-grey;
    font-size: 16px;
    color: $textColor;
    font-family: 'MontserratMedium', sans-serif;
    border-radius: 4px;
    padding: 0 5px;
    &_error_wrap {
      height: 55px;
    }
    &:focus {
      border: 1px solid $color-green;
    }
    &.error {
      border: 1px solid red;
    }
    &.readonly {
      background-color: #DCDCDC;
    }
    &.grey {
      background-color: $color-grey;
    }
    &::placeholder {
      font-family: 'MontserratMedium', 'sans-serif';
    }
  }
  &_button {
    width: 150px;
    height: 30px;
    font-size: 14px;
    font-family: 'MontserratMedium', sans-serif;
    display: inline-block;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid transparent;
    transition: background-color .2s ease, border .2s ease;
    &:last-child {
      margin-right: 0;
    }
    &.submit {
      color: #ffffff;
      background-color: $color-green;
      &:hover {
        cursor: pointer;
        background-color: transparent;
        border: 1px solid $color-green;
        color: $textColor;
      }
    }
    &.clear {
      &:hover {
        cursor: pointer;
        border: 1px solid $color-grey;
        background-color: transparent;
        color: $textColor;
      }
    }
  }
  &-btn-wrap {
    display: flex;
    justify-content: flex-end;
  }
}

.toggle-form {
  position: absolute;
  top: -40px;
  height: 30px;
  display: inline-block;
  width: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  &:before {
    content: '';
    display: block;
    width: 30px;
    height: 8px;
    background-color: $color-green;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%) rotate(-20deg);
    transition: transform 0.4s ease;
  }
  &:after {
    content: '';
    display: block;
    width: 30px;
    height: 8px;
    background-color: $color-green;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%) rotate(20deg);
    transition: transform 0.4s ease;
  }
}

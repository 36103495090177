@import '../../../../variables';

.data-picker-with-label-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  span {
    color: red;
  }
}

.data-pickers-wrap {
  display: block;
}

.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom]
.react-datepicker__triangle::after {
  border-bottom-color: $color-green;
}

.data-picker {
  height: 30px;
  width: 100%;
  outline: none;
  border: 1px solid $color-grey;
  font-size: 16px;
  color: $textColor;
  font-family: 'MontserratMedium', sans-serif;
  padding: 0 5px;
  border-radius: 4px;
  &.grey {
    background-color: $color-grey;
  }

  &.error {
    border: 0.5px solid rgba(255, 0, 0, 0.4);
    box-shadow: 0px 4px 10px rgba(255, 0, 0, 0.25);
  }

  &:hover {
    cursor: pointer;
  }

  &_error {
    margin: 0px;
    width: 400px;
    text-align: center;
    color: red;
    font-family: "MontserratMedium", sans-serif;
    font-size: 12px;
    height: 15px;
    margin-bottom: 10px;
    display: block;
  }

  &_text {
    line-height: 40px;

    &:first-child {
      margin-left: 0;
    }

    font-family: "MontserratBold", sans-serif;
    font-size: 16px;
    color: $textColor;
  }
}

.react-datepicker {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border: 1px solid $color-green;

  &__header {
    background-color: $color-green;
  }

  &__current-month {
    color: white;
  }

  &__day-name {
    color: white;
  }

  &__navigation-icon::before {
    border-color: white;
  }

  &__day--selected {
    background-color: $color-green;

    &:hover {
      background-color: $color-green;
    }
  }

  &__day--keyboard-selected {
    background-color: $color-green;

    &:hover {
      background-color: $color-green;
    }
  }
}


.react-datepicker-wrapper {
  height: 30px;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg) translateY(50%);
  right: -20px;
  top: 25%;
}

.react-datepicker__year-dropdown-container {
  background: transparent;
  border: transparent;
  outline: none;

}

.react-datepicker__year-read-view .react-datepicker__year-read-view--selected-year {
  background: transparent;
  border: none;
  color: white;
  font-family: "OpenSans Regular", sans-serif;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

.react-datepicker__year-read-view--down-arrow {
  border-color: white;
  &:hover {
    border-color: white;
  }
}

.react-datepicker__year-read-view {
  &:hover {
    .react-datepicker__year-read-view--down-arrow {
      border-color: #a6a6a6;
    }
  }
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  padding: 0;
}

.react-datepicker__year-option {
  &.react-datepicker__year-option--selected_year {
    background-color: $color-green;
    color: white;
    & span {
      color: $color-green;
    }
  }
  &:hover {
    background-color: $color-green;
    color: white;
  }
  & a {
    margin-top: 0;
    height: 20px;
  }
  & .react-datepicker__navigation--years-upcoming {
    &::before {
      content: 'X';
      display: block;
      position: absolute;
      top: 50%;
      width: 15px;
      height: 15px;
      background: url("../../../../img/icons/arrow.png") center / contain;
      transform: rotate(180deg) translateX(-50%) translateY(25%);
    }
  }
  & .react-datepicker__navigation--years-previous {
    top: 0;
    &::before {
      content: 'X';
      display: block;
      position: absolute;
      bottom: 50%;
      width: 15px;
      height: 15px;
      background: url("../../../../img/icons/arrow.png") center / contain;
      transform: translateX(50%) translateY(50%);
    }
  }
}
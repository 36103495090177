@import '../../variables.scss';

.toolbar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    background-image: linear-gradient($color-green, $color-green);
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: 100% 0;
    transition: background-size .3s, background-color .3s, color .3s;
    &:first-child {
        border-radius: 5px 5px 0 0;
        border-bottom: 0.2px solid $color-green;
    }
    &:last-child {
        border-radius: 0 0 5px 5px;
        border-top: 0.2px solid $color-green;
    }
    &:hover {
        color: white;
        cursor: pointer;
        background-size: 100% 100%;
    }
}
@import "../../variables";

.confirm-modal {
  &-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.2);
    z-index: 10;
  }
  &-body {
    background-color: white;
    border-radius: 20px;
    width: 400px;
    min-height: 140px;
    padding: 10px 20px;
    position: relative;
    & h3 {
      margin: 0;
      font-family: 'MontserratBold', sans-serif;
      font-size: 16px;
    }
    & p {
      font-family: 'MontserratMedium', sans-serif;
      font-size: 14px;
    }
  }
  &-btn-wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    & .btn {
      width: 50px;
      height: 30px;
      margin-left: 10px;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: border 0.2s ease;
      font-family: 'MontserratMedium', 'sans-serif';
      &.primary {
        background-color: $color-green;
        color: white;
        transition: background-color  0.2s ease;
        &:hover {
          background-color: transparent;
          color: $textColor;
          border: 1px solid $color-green;
        }
      }
      &.default {
        color: $textColor;
        background-color: $color-grey-light;
        &:hover {
          background-color: transparent;
          border: 1px solid $color-grey-light;
          transition: background-color  0.2s ease;
        }
      }
    }
  }
  &-close {
    position: absolute;
    top: 3px;
    right: 10px;
    font-family: 'OpenSans Bold', sans-serif;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
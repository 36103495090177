$color-grey: #B7BCC9;
$color-grey-light: #f1e9e9;
$color-grey-border: #e3e3f5;
$color-green: #5CDB94;
$color-aqua: #33CCCC;
$color-tab-selected: #F8F9FB;
$padding-menu-bottom-top: 30;
$padding-menu-left-right: 15;
$height-tab-panel: 30;
$padding-tab-content-top: 30;
$width-menu-plus-width-scroll: 276px;
$textColor: #05396B;
$bgMenuColor: #FAFAFA;
$greyColor: #C4C4C4;


@import '../../../../variables';

.input-with-tags-block {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    &__text {
        line-height: 40px;
        font-family: 'MontserratBold', 'sans-serif';
        font-size: 16px;
        color: $textColor;
    }
    &_input {
        height: 30px;
        outline: none;
        border: 1px solid $color-grey;
        font-size: 16px;
        color: $textColor;
        border-radius: 4px;
        padding: 0 5px;
        font-family: 'MontserratMedium', sans-serif;
        &:focus {
            border: 1px solid $color-green;
        }
        &.error {
            border: 1px solid red;
        }
    }
}

.tegs-block {
    margin-bottom: 10px;
    width: 355px;
    &_error {
        font-family: "OpenSans Regular", sans-serif;
        font-size: 12px;
        color: red;
    }
}
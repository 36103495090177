@import './src/variables.scss';

.requirements-block {
    padding: 15px 20px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.2s ease;
    backface-visibility: hidden;
    display: flex;
    flex-direction: row;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    z-index: 10;
    &:hover {
        cursor: pointer;
    }
    &_text {
        margin-left: 25px;
        display: inline;
        padding: 0;
        width: 100%;
        // margin: 0px;
        font-family: 'MontserratMedium', 'sans-serif';
        font-size: 16px;
        color: $textColor;
    }
}

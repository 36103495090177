@import '../../../variables.scss';

.requirement-tab-wrap {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.MuiTabs-flexContainer {
    border: 1px solid $color-grey;
    border-bottom: transparent;
}

.MuiTab-root.Mui-selected {
    color: #ffffff;
    background-color: $color-green;
}

.MuiButtonBase-root {
    font-family: 'MontserratRegular', 'sans-serif';
    color: $textColor;
}

.MuiTabPanel-root {
    border: 1px solid $color-grey;
}

.MuiTabs-indicator {
    background-color: #ffffff;
}
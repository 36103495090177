@import './variables.scss';

body {
  margin: 0;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$green: #3ED3A3;

p {
  margin: 0px 0px;
}

.rs-table-cell-content {
  white-space: pre-line;
  color: #000000;
  font-size: 14px;
  font-family: 'OpenSans Regular', sans-serif;
}

.rs-table-cell-header-icon-sort {
  color: #FFFFFF;
  font-size: 20px;
}

.rs-table-cell-header-sort-wrapper {
  margin-left: 0px;
}

.rs-input:hover:not(:disabled) {
  border-color: $color-green;
}
.rs-input:focus:not(:disabled) {
  border-color: $color-green;
}

.rs-input:focus {
  box-shadow: none;
}

.tab-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.plus-icon {
  display: block;
  margin-top: 10px;
        &:hover {
            cursor: pointer;
            color: $color-green;
        }
}

*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  background: $greyColor;
  border: 3px solid white;
}
*::-webkit-scrollbar-thumb {
  background-color: $greyColor;
  border-radius: 20px;
  border: 3px solid $greyColor;
}
*::-webkit-scrollbar-button {
  background-color: transparent;
}
.modal {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    position: relative;
    min-width: 300px;
    max-height: 1000px;
    background-color: white;
    border-radius: 10px;
    padding: 25px 20px;
  }
  &-close {
    position: absolute;
    top: 3px;
    right: 10px;
    font-family: 'OpenSans Bold', sans-serif;
    font-size: 20px;
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
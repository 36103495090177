@import "src/variables";

.form-add-files {
  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &_title {
    font-family: "MontserratRegular", "sans-serif";
    font-size: 18px;
    color: $textColor;
  }
  &_file-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 600px;
    max-height: 400px;
    overflow: auto;
    &_item {
      display: flex;
      align-items: center;
      gap: 15px;
      color: $textColor;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      &_checkbox {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &_menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &_loader_wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@import './src/variables.scss';

.checkbox {
    &-wrap {
        display: flex;
        flex-direction: row;
        height: 60px;
    }
    &-label {
        font-family: "MontserratBold", sans-serif;
        font-size: 16px;
        color: $textColor;
        margin-right: 10px;
    }
    &-input {
        width: 20px;
        height: 20px;
    }
}
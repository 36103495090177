@font-face {
    font-family: 'OpenSans Bold';
    src: local('OpenSans Bold'), url(./fonts/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSans Regular';
    src: local('OpenSans Regular'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSans SemiBold';
    src: local('OpenSans SemiBold'), url(./fonts/OpenSans-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'MontserratMedium';
    src: local('MontserratMedium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'MontserratBold';
    src: local('MontserratBold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: local('MontserratRegular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

.container {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 270px calc(100vw - 276px);
    grid-template-rows: 1fr;
}

body.lock {
    overflow: hidden;
}

input {
    height: 30px;
    outline: none;
    border: 1px solid #B7BCC9;
    font-size: 16px;
    color: #05396B;
    font-family: 'MontserratMedium', sans-serif;
    border-radius: 4px;
    padding: 0 5px;
}

input:focus {
    border: 1px solid #5CDB94;
}

input.error {
    border: 1px solid red;
}
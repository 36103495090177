@import '../../variables.scss';

.menu-nav-item {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    transition: color 0.2s;
    color: #000000;
    &:hover {
        cursor: pointer;
    }
    &_expand_icon {
        &:hover {
            color: #3ED3A3; 
        }
    }
    &_text {
        font-family: 'MontserratMedium', sans-serif;
        font-size: 16px;
        color: $textColor;
        display: inline;
    }
    &_link {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        border-radius: 10px;
        transition: background 0.2s ease;
        &:hover {
            background: linear-gradient(89.85deg, rgba(141, 228, 175, 0.28) 0.1%, rgba(141, 228, 175, 0) 149.74%);
        }
    }
    &_children {
        display: flex;
        flex-direction: column;
        margin-left: 18px;
        overflow: hidden;
        transition:height 0.4s ease-out;
        height:0;
    }
}
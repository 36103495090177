.modal-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  max-width: 700px;
  padding: 40px 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.close-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 15px;
  height: 15px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}